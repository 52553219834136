  .container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: 23% 50% 23%;
    gap: 22px;
  }

  .item {
    background-color: #E4F1FD;
    height: 436px;
    border-radius: 1rem;
    width: 100%;
  }

  .item1 { grid-column: 1; }
  .item2 { grid-column: 2; }
  .item3 { grid-column: 3; }

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    text-align: center;
    align-items: center;
    margin: 0 auto 6.25rem;
  }

  .title {
    max-width: 50rem;
  }

  .description {
    max-width: 50rem;
    font-size: 18px;
  }

  .secondRow {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
  }

  .item4, .item5 {
    height: 436px;
  }

  @media (max-width: 768px) {
    .textContainer {
      gap: 1.5rem;
      margin-bottom: 40px;
    }

    .gridContainer {
      grid-template-columns: 1fr 1fr;
    }

    .item, .secondRow {
      height: 233px;
    }
    .item1, .item3, .item5 {
      display: none;
    }

    .item4, .item2 {
      width: calc(100% - 0.25rem);
    }

    .item2, .item4, .secondRow {
      grid-column: 1 / -1;
    }
  }
