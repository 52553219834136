.title {
  composes: title from './ImagesSection.module.css';
  text-transform: uppercase;
}

.description {
  composes: description from './ImagesSection.module.css';
  font-family: var(--font-roboto);
  line-height: 150%;
  font-size: 18px;
  text-align: center;
}