.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 3rem;
}

.description {
  text-align: center;
  max-width: 50rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 412px);
  grid-auto-rows: auto;
  grid-auto-flow: row dense;
  gap: 1.5rem;
  justify-content: center;
  align-items: start;
}

.starRating {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
  
.gridItem {
  background-color: #FEF9F4;
  padding: 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}
  
.middleColumn {
  transform: translateY(-35px);
  z-index: 1;
}
  
.gridContainer {
  display: flex;
  align-items: center;
  height: 40rem;
  margin-top: 6.25rem;
}

.text {
  line-height: 150%;
  letter-spacing: 0.18px;
}

.author {
  font-weight: 500;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .title {
    margin-bottom: 2rem;
  }

  .gridContainer {
    height: auto;
    margin-top: 3.5rem;
  }
  
  .middleColumn {
    transform: none;
    display: none; /* show less content on mobile */
  }
}
  