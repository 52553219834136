.container {
  composes: container from './TakePartSection.module.css';
  height: 660px;
}

.content {
  composes: content from './TakePartSection.module.css';
  margin: auto;
}

.flexboxFirst {
  composes: flexboxFirst from './TakePartSection.module.css';
  padding: 64px;
  background-image: url('/lp/assets/cz/hero_background_left_desktop.png');
}

.title {
  composes: title from './TakePartSection.module.css';
  text-transform: uppercase;
  max-width: 40rem;
  margin-bottom: 2rem;
  line-height: 110%;
}

.button {
  composes: button from './TakePartSection.module.css';
  align-self: center;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

.date {
  composes: date from './TakePartSection.module.css';
  color: var(--accent-color);
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .date {
    max-width: 12rem;
    align-self: center;
  }

  .container {
    height: auto;
  }

  .flexboxFirst {
    padding: 16px;
    flex-basis: auto;
  }

  .flexboxFirst {
    text-align: center;
    padding: 48px 16px;
    background-image: url('/lp/assets/cz/hero_background_left_mobile.png');
  }

  .flexboxSecond {
    background-image: url('/lp/assets/cz/hero_background_right_mobile.png');
    height: 18rem;
    min-width: 0;
  }

  .pricebox {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    align-items: center;
  }
}
