.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  color: var(--hero-font-color);
}

.flexboxFirst {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
  flex-basis: auto;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.flexboxFirst {
  padding: 64px;
  background-image: url('/assets/generic/hero_background.png');
}

.pricebox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.date, .title, .description, .mainPrice, .extra, .secondaryText {
  margin: 0;
  color: var(--hero-font-color);
}

.title, .description, .extra {
  margin-bottom: 20px;
}

.button {
  max-width: 15rem;
}

.extra {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 10px;
    padding: 10px
  }

  .flexboxFirst {
    padding: 16px;
    flex-grow: 0;
  }

  .pricebox {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem;
  }

  .button {
    max-width: 100%;
    padding: 8px 16px;
  }
}
