.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.date {
  margin-bottom: 1.5rem;
  color: var(--accent-color);
}

.flexboxFirst, .flexboxSecond {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
  flex-basis: auto;
}

.flexboxFirst {
  padding: 64px;
  color: var(--hero-font-color);
  background-image: url('/assets/generic/hero_background.png');
}

.flexboxSecond {
  padding: 36px;
  color: var(--hero-right-color);
  background-image: url('/assets/generic/hero_background.png');
}

.pricebox {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}

.title {
  font-weight: 500;
}

.mainPrice {
  font-size: 30px;
  font-weight: 500;
}

.title, .description, .extra {
  margin-bottom: 20px;
}

.button {
  align-self: flex-start;
}

.extra {
  opacity: 0.7;
}

.secondaryText {
  
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 10px;
  }

  .flexboxFirst, .flexboxSecond {
    padding: 16px;
    flex-grow: 0;
  }

  .pricebox {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem;
  }
}
