.container {
  composes: container from './HeroSection.module.css';
  height: 670px;
}

.flexboxFirst {
  composes: flexboxFirst from './HeroSection.module.css';
  padding: 64px;
  background-image: url('/lp/assets/chm/hero_background_left_desktop.png');
}

.flexboxSecond {
  composes: flexboxSecond from './HeroSection.module.css';
  padding: 36px;
  background-image: url('/lp/assets/chm/hero_background_right_desktop.png');
  min-width: 22rem;
  border-radius: 24px;
}

.title {
  composes: title from './HeroSection.module.css';
  text-transform: uppercase;
  max-width: 30rem;
  font-weight: 400;
}

.date {
  color: #193BEE;
}

.title, .description {
  composes: title, description from './HeroSection.module.css';
  color: #FFF;
}

.button {
  composes: button from './HeroSection.module.css';
  font-weight: 500;
  letter-spacing: 1.8px;
}

.button:hover {
  background-color: #483D3C;
}

.link {
  margin-top: auto;
}

@media (max-width: 768px) {
  .date {
    max-width: 12rem;
    align-self: center;
  }

  .container {
    height: auto;
  }

  .flexboxFirst, .flexboxSecond {
    padding: 16px;
    flex-basis: auto;
  }

  .flexboxFirst {
    padding: 48px 16px;
    background-image: url('/lp/assets/chm/hero_background_left_mobile.png');
    background-position: center;
    height: 532px;
  }

  .title, .description {
    display: none;
  }

  .flexboxSecond {
    background-image: url('/lp/assets/chm/hero_background_right_mobile.png');
    height: 16rem;
    background-position: right;
    min-width: 0;
  }

  .pricebox {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    align-items: center;
  }

  .button {
    justify-content: space-between;
    max-width: 60%;
  }

  .button > svg {
    align-self: center;
  }
}
