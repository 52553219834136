.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 133px;
  background-color: var(--quote-section-color);
  color: var(--text-accent-color);
  padding: 0;
}

.mainImage {
  width: 530px;
  display: inline-block;
  background-image: url('/lp/assets/generic/hero_background.png');
  background-repeat: no-repeat;
}

.quoteContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding-bottom: 2.5rem;
}

.quoteDecoration {
  margin-right: 1rem;
}

.quote {
  font-size: 36px;
  margin: 4rem 4rem 0 0;
  line-height: 110%;
}

.quoteFooter {
  display: flex;
  align-items: center;
  padding: 33px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: #D9D9D9;
  display: flex;
  flex-direction: row;
  margin-right: 5rem;
  gap: 34px;
}

.quoteCite {
  font-weight: 500;
  font-style: normal;
}

.description {
  margin-top: auto;
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
    gap: 0;
  }

  .quoteContainer {
    padding: 1rem 1.5rem 2rem;
  }

  .quote {
    font-size: 28px;
    margin: 0;
  }

  .mainImage {
    height: 12rem; 
  }

  .quoteFooter {
    margin-right: 2rem;
  }

}