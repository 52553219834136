.container {
  composes: container from './ImagesSection.module.css';
}

.gridContainer {
  composes: gridContainer from './ImagesSection.module.css';
}

.item1 {
  composes: item1 from './ImagesSection.module.css';
}

.item2 {
  composes: item2 from './ImagesSection.module.css';
}

.item3 {
  composes: item3 from './ImagesSection.module.css';
}

.textContainer {
  composes: textContainer from './ImagesSection.module.css';
}

.title {
  composes: title from './ImagesSection.module.css';
}

.description {
  composes: description from './ImagesSection.module.css';
}

.secondRow {
  composes: secondRow from './ImagesSection.module.css';
}

.item4 {
  composes: item4 from './ImagesSection.module.css';
}

.item5 {
  composes: item5 from './ImagesSection.module.css';
}


@media (max-width: 768px) {
  .textContainer {
    gap: 1.5rem;
    margin-bottom: 40px;
  }

  .gridContainer {
    grid-template-columns: 1fr 1fr;
  }

  .item1, .item2, .item3 {
    display: none;
  }

  .item4, .item5 {
    display: initial;
    width: calc(100% - 0.25rem);
    border-radius: 1rem;
  }

  .item4, .item5, .secondRow {
    grid-column: 1 / -1;
  }

  .secondRow {
    height: 436px;
    margin-bottom: 2rem;
  }
}