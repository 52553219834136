.container {
  composes: container from './HeroSection.module.css';
}

.flexboxFirst {
  composes: flexboxFirst from './HeroSection.module.css';
  padding: 4rem 4rem 10rem;
  background-image: url('/lp/assets/cz/hero_background_left_desktop.png');
}

.flexboxSecond {
  composes: flexboxSecond from './HeroSection.module.css';
  padding: 36px;
  background-image: url('/lp/assets/cz/hero_background_right_desktop.png');
  min-width: 22rem;
  border-radius: 24px;

  @media (min-width: 1300px) {
    background-position: right;
    background-size: auto;
    background-color: #ffe3e3;
    min-height: 550px;
  }
}

.title {
  composes: title from './HeroSection.module.css';
  text-transform: uppercase;
  max-width: 34rem;
}

.description {
  composes: description from './HeroSection.module.css';
  font-family: var(--font-roboto);
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.date {
  composes: date from './HeroSection.module.css';
}

.pricebox {
  composes: pricebox from './HeroSection.module.css';
  margin-bottom: 0.5rem;
}

.secondaryText {
  composes: secondaryText from './HeroSection.module.css';
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  max-width: 13rem;
  font-family: var(--font-roboto);
}

.priceDescription {
  margin-bottom: 0.2rem;
}

@media (max-width: 768px) {
  .date {
    max-width: 12rem;
    align-self: center;
  }

  .container {
    height: auto;
  }

  .flexboxFirst,
  .flexboxSecond {
    padding: 16px;
    flex-basis: auto;
  }

  .flexboxFirst {
    text-align: center;
    padding: 48px 16px;
    background-image: url('/lp/assets/cz/hero_background_left_mobile.png');
  }

  .title {
    font-size: 32px;
  }

  .flexboxSecond {
    background-image: url('/lp/assets/cz/hero_background_right_mobile.png');
    height: 18rem;
    min-width: 0;
    padding: 16px 16px 32px;
  }

  .secondaryText {
    margin: auto 0 0;
    text-align: center;
    align-self: center;
    font-weight: 400;
    font-size: 16px;
  }

  .pricebox {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    align-items: center;
  }
}
